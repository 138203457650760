.collection-card {
    background-color: white;
    border-radius: 1px;
    border: 1px solid #c5cede;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(90, 117, 152, 0.3);
    display: table;
    flex-direction: column;
    position: relative;
    padding: 12px 20px 12px 20px;
    transition: all 200ms;
    width: 100%;

    &:hover {
        box-shadow: 1px 3px 10px rgba(90, 117, 152, 0.4);
    }

    &:focus-visible {
        border: 2px solid blue;
    }

    .collection-stats {
        color: #152935;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .created-at {
        color: #5a7598;
        font-size: 11px;
        padding: 0px;
        margin: 0px;
        margin-bottom: 2px;
    }

    h3 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 20px;
        transition: color 200ms;
        &:hover {
            color: #40a9ff;
        }
    }
    button {
        display: block;
        float: right;
        transform: translateY(50%);
    }
    .custom-column {
        float: left;
    }
    .left {
        width: 95%;
    }
    .right {
        width: 5%;
    }
}
