@tailwind base;
@tailwind components;
@tailwind utilities;

// Color Variables
$PaleGrey: #f1f1f2;
$LightGrey: #b2b2b2;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
ul {
  list-style: None;
  padding: 0px;
  margin: 0px;
}

h1 {
  width: 100%;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.75rem;
  margin: 0;
  padding: 2px 0;
}

h2 {
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: left;
}

a.title {
  color: black;
  text-align: left;
}

h3 {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
}

p {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

#root {
  width: 100vw;
  height: 100vh;
}

.App {
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 88px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'content'
    'side-nav';
  @media (min-width: 859px) {
    grid-template-rows: 1fr;
    grid-template-columns: 88px 1fr;
    grid-template-areas: 'side-nav content';
  }
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
  grid-area: content;
  overflow-y: hidden;
}

.collection-button {
  border: none;
  background: none;
  color: white;
  background-color: #1c4d5e;
  height: 24px;
  width: fit-content;
}
.dataset-joins {
  height: 100%;

  ul {
    overflow-y: auto;
    height: 500px;
  }
}

.tag-selector {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.selected {
  font-weight: bold;
}

button.primary-button {
  border: none;
  background: none;
  background-color: #1c4d5e;
  font-weight: bold;
  color: white;
  outline: none;
  padding: 0.25rem 0.75rem;
  min-width: 135px;
}

button::-moz-focus-inner {
  border: 0;
}
input[type='button'] {
  outline: none;
}
input[type='button']::-moz-focus-inner {
  border: 0;
}

button.header-button {
  color: white;
  width: 100%;
  background-color: transparent;
  color: white;
  border: none;
  display: flex;
  justify-content: space-between;
  &:active {
    outline: none;
  }
}

.bold {
  font-weight: 700;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  li {
    padding: 5px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      color: $LightGrey;
    }
    &.active {
      font-weight: 600;
    }
    &:hover {
      text-decoration: underline;
      &.disabled {
        text-decoration: none;
      }
    }
  }
}
.search {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0px 20px 0px;

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }
}

.tabs {
  display: flex;
  border-bottom: 3px solid #edf2f5;
  box-sizing: border-box;
  padding: 20px 0px 5px 0px;
  margin-bottom: 15px;

  .tab-link,
  button {
    width: fit-content;
    margin-right: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: black;
    white-space: nowrap;
  }
  button.active {
    color: #009aa6;
    border-bottom: 2px solid #009aa6;
  }
}
