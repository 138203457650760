.Breadcrumb {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  a {
    color: grey;
  }
}
