.collection-page {
    @media (min-width: 875px) {
        flex-direction: row;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: white;

    section {
        margin-bottom: 20px;
    }

    h1 {
        text-align: center;
    }
    .collection-details {
        width: 360px;
        padding: 20px 40px;

        h2 {
            word-break: break-all;
        }

        .dataset-url {
            word-break: break-all;
        }

        button {
            background: none;
            border: 1px solid #336699;
            grid-area: collection;
            background-color: #ffffff;
            color: #336699;
            width: max-content;
            border-radius: 2px;
        }

        .share-icons {
            padding: 10px 10px 10px 0;
            button {
                margin-right: 10px;
            }
        }
    }
    .collection-content {
        flex: 1;
        background-color: #f7fafc;
        padding: 20px 40px;
        overflow-y: auto;
    }
}
