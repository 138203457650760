.thematic-similarity-explorer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}
.dataset-recomendataions-theme-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}
