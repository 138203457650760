.collections-page {
    width: 100%;
    height: 100%;
    background-color: #f7fafc;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .collections-list {
        flex: 1;
        overflow-y: auto;
        ul {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
            grid-column-gap: 30px;
            grid-row-gap: 10px;
            cursor: pointer;
        }
    }
    .collection-icon {
        width: 88px;
        height: 88px;
        border-radius: 88px;
        background-color: #e7eef7;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: #5a7598;
        margin-bottom: 15px;
    }
    .no-collections {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
            font-size: 16px;
            color: #1c4d5e;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }
    .what-are-collections {
        max-width: 400px;
        background-color: #e7f6fc;
        border: 1px solid #009aa6;
        border-radius: 2px;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 20px;
        h3 {
            font-size: 12px;
            color: #152935;
        }
        p {
            font-size: 11px;
            color: #1c232c;
        }
    }
}
