.collection-tab {
    @media (min-width: 859px) {
        left: 100px;
        top: -25px;
        bottom: inherit;
        transform: none;
    }

    bottom: 0px;
    top: inherit;
    transform: translate(-50%, -100px);
    left: inherit;

    position: absolute;
    background-color: white;
    box-sizing: border-box;
    padding: 0px;
    width: 330px;
    height: 405px;
    border: 1px solid #b9c8d1;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 2px;

    text-align: left;
    display: flex;
    flex-direction: column;
    color: black;
    h2 {
        text-align: left;
        color: #0b0e12;
        font-weight: 700;
        padding: 20px 20px 0px 20px;
    }
    h3 {
        font-size: 14px;
        padding: 5px 0px;
    }
}

.collections-tab-create-options {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
    input {
        box-sizing: border-box;
        padding: 5px 10px;
        margin-bottom: 10px;
    }
}

.collections-tab-create {
    flex: 1;
    display: flex;
    flex-direction: column;

    ul {
        overflow-y: auto;
        flex: 1;
        height: 228px;
    }
    li {
        width: 100%;
        box-sizing: border-box;
    }
}

.collection-tab {
    border: 1px solid #b9c8d1;
}

@media (min-width: 859px) {
    .collection-tab:after,
    .collection-tab:before {
        right: 100%;
        top: 40px;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: flex;
        flex: 1;
    }

    .collection-tab:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 25px;
        margin-top: -25px;
    }
    .collection-tab:before {
        border-color: rgba(185, 200, 209, 0);
        border-right-color: #b9c8d1;
        border-width: 26px;
        margin-top: -26px;
    }
}

.add-to-collection {
    flex: 1;
    display: flex;
    flex-direction: column;
    .add-to-header {
        border-bottom: 1px solid #e7eef7;
        padding: 10px 20px 0px 20px;
        height: 44px;
        .collection-name {
            color: #152935;
            font-weight: normal;
            padding-bottom: 0px;
            margin-bottom: 4px;
        }
        button {
            color: #1693a3;
            background-color: transparent;
            padding: 0px;
            width: fit-content;
        }
    }
}

.collections-tab-view {
    display: flex;
    flex-direction: column;
}
.collection-tab-current-collection {
    flex: 1;
    padding: 0px;
    color: black;
    display: flex;
    ul {
        overflow-y: auto;
        flex: 1;
        height: 309px;
    }
    li {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 20px;
    }
}

.collection-tab-dataset {
    margin: 0px;
    position: relative;
    div {
        margin-bottom: 0px;
        padding: 0px;
    }
    > button {
        display: none !important;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 2;
        height: 100%;
        color: #d9003a;
        background-color: #f7fafc;
    }
    p {
        padding: 0px;
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2px 0px;
        &.name {
            font-size: 12px;
            color: black;
        }
        &.agency {
            font-size: 11px;
            color: #5a7598;
        }
    }
    span.name {
      font-size: 12px;
      color: black;
    }

    &:hover {
        background-color: #f7fafc;
    }
    &:hover > button {
        display: block !important;
    }
}

.collection-tab-buttons {
    box-sizing: border-box;
    border-top: 1px solid #5a7595;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
        height: 32px;
        color: white;
    }
    a {
        color: white;
    }
}
