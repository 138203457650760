.datasets-loading {
  .dataset {
    min-height: 172px;
  }
  .dataset-title {
    max-width: 30%;
    height: 35px;
  }
  .dataset-description {
    margin-top: 10px;
  }
}
