nav.side-nav {
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 859px) {
    flex-direction: column;
    padding: 16px 0px;
    align-items: center;
    justify-content: start;
  }
  background-color: #0b0e12;
  color: white;
  box-sizing: border-box;
  grid-area: side-nav;
  svg {
    fill: white;
    stroke: white;
  }

  .explore,
  .about {
      svg {
        margin: 0 auto;
      }
  }

  .active-nav {
    border-right: none;
    border-top: 4px solid #ffbb00;
    @media (min-width: 859px) {
      border-right: 4px solid #ffbb00;
      border-top: 0px;
    }
  }

  .header-button {
    display: flex;
    flex-direction: column;
  }
  .collections-button {
    > button {
      height: 57px;
    }
    h1 {
      margin-left: -6px;
    }
  }

  > a,
  > div {
    text-align: center;
    min-width: 90px;
    height: 70px;
    padding-top: 10px;
    @media (min-width: 859px) {
      padding: 0px 14px;
      min-height: 60px;
      margin-bottom: 69px;
      width: 100%;
    }
    box-sizing: border-box;
    svg {
      color: white;
      width: 32px;
      height: 32px;
    }
  }
  .scout-h2 {
    color: white;
    font-weight: 300;
    size: 14px;
    text-align: center;
  }
  .scout-sub {
    color: white;
    font-weight: 400;
    font-size: 8px;
    text-align: center;
  }

  h1 {
    color: white;
    font-size: 11px;
  }

  img {
    width: 32px;
  }
  button {
    align-items: center;
  }

  .login {
    margin-top: auto;
    margin-bottom: 0px;
  }
}
