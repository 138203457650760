.mutli-selector {
    box-sizing: border-box;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    border-bottom: 1px solid #7e7e91;
    button {
        width: 100%;
    }
    h2 {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .multi-list {
        overflow-y: auto;
        flex: 1;

        li {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 5px;
            input {
                margin-right: 10px;
            }
            .item-name {
                flex: 1;
            }
            span.pill {
                color: white;
                background-color: #13181f;
                padding: 2px 3px;
                border-radius: 5px;
                font-weight: 700;
            }
        }
    }
    .checkbox {
        width: 16px;
        height: 16px;
    }
    .search {
        padding: 10px 0px 10px 0px;
        input {
            padding: 16px 8px;
            box-sizing: border-box;
            background-color: #13181f;
            font-size: 11px;
            color: #c5ced9;
            height: 24px;
            border: none;
        }
    }
}
